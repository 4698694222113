import server from '../request';

export default {
  // 个人信息
  // 个人信息详情
  employeeDetail (data) {
    return server.post('/xiaozi-xmo/wireless/saas/employee/detail', data);
  },
  // 编辑个人信息
  updateEmployee (data) {
    return server.post('/xiaozi-xmo/wireless/saas/employee/update/employee', data);
  },

  // 收货地址
  // 查询个人所有租户（客户）的收货地址列表
  addressList (data) {
    return server.post('/xiaozi-xmo/wireless/miniapp/deliveryAddress/list', data);
  },
  // 查询个人当前租户（客户）的收货地址列表
  addressOwn (data) {
    return server.post('/xiaozi-xmo/wireless/miniapp/deliveryAddress/list/own', data);
  },
  // 新增收货地址
  addAddress (data) {
    return server.post('/xiaozi-xmo/wireless/miniapp/deliveryAddress/add', data);
  },
  // 编辑收货地址
  updateAddress (data) {
    return server.post('/xiaozi-xmo/wireless/miniapp/deliveryAddress/update', data);
  },
  // 删除收货地址
  deleteAddress (data) {
    return server.post('/xiaozi-xmo/wireless/miniapp/deliveryAddress/delete', data);
  },

  // 发票信息
  // 查询个人所有租户（客户）的发票信息列表
  invoiceList (data) {
    return server.post('/xiaozi-xmo/wireless/miniapp/invoice/list', data);
  },
  // 查询个人当前租户（客户）的发票信息列表
  invoiceOwn (data) {
    return server.post('/xiaozi-xmo/wireless/miniapp/invoice/list/own', data);
  },
  // 新增发票信息
  addInvoice (data) {
    return server.post('/xiaozi-xmo/wireless/miniapp/invoice/add', data);
  },
  // 编辑发票信息
  updateInvoice (data) {
    return server.post('/xiaozi-xmo/wireless/miniapp/invoice/update', data);
  },
  // 删除发票信息
  deleteInvoice (data) {
    return server.post('/xiaozi-xmo/wireless/miniapp/invoice/delete', data);
  },
};
