<template>
  <div class="invoice-form-box">
    <el-form
      :model="form"
      :rules="formRules"
      ref="form"
      style="width: 100%;">
      <el-row>
        <el-col :span="12">
          <el-form-item label="企业名称：" prop="company" :label-width="labelWidth">
            <el-input v-model.trim="form.company" placeholder="请输入"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="税号：" prop="taxNumber" :label-width="labelWidth">
            <el-input v-model.trim="form.taxNumber" placeholder="请输入"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="开票电话：" prop="phoneNumber" :label-width="labelWidth">
            <el-input v-model.trim="form.phoneNumber" placeholder="请输入"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="开户行：" prop="bank" :label-width="labelWidth">
            <el-input v-model.trim="form.bank" placeholder="请输入"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="银行账号：" prop="bankAccount" :label-width="labelWidth">
            <el-input v-model.trim="form.bankAccount" placeholder="请输入"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="所在地区：" prop="contactAreaId" :label-width="labelWidth">
            <area-cascader
              :provinceId.sync="form.contactProvinceId"
              :provinceName.sync="form.contactProvinceName"
              :cityId.sync="form.contactCityId"
              :cityName.sync="form.contactCityName"
              :areaId.sync="form.contactAreaId"
              :areaName.sync="form.contactAreaName"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item label="详细地址：" prop="contactAddrs" :label-width="labelWidth">
            <el-input v-model.trim="form.contactAddrs" placeholder="请输入"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div class="button-group">
      <el-button @click="close" size="small">取消</el-button>
      <el-button @click="save" size="small" type="primary">保存</el-button>
    </div>
  </div>
</template>
<script>
import AreaCascader from '@/components/common/areaCascader';
import UserDrawer from '@/api/userDrawer';
export default {
  name: 'EDIT_INVOICE',
  components: {
    AreaCascader,
  },
  props: {
    formData: {
      type: Object,
      default: () => {},
    },
  },
  watch: {
    formData: {
      deep: true,
      immediate: true,
      handler (param) {
        if (param) this.form = param;
      },
    },
  },
  data () {
    const checkPhoneNumber = (rule, value, cb) => {
      const regPhone = /^[1][0-9]{10}$/;
      if (value && value.length === 11 && !regPhone.test(value)) {
        cb(new Error('开票电话格式有误'));
      }
      return cb();
    };
    return {
      labelWidth: '120px',
      form: {
        id: '',
        company: '',
        taxNumber: '',
        phoneNumber: '',
        bank: '',
        bankAccount: '',
        contactAreaId: '0',
        contactAreaName: '',
        contactCityId: '0',
        contactCityName: '',
        contactProvinceId: '0',
        contactProvinceName: '',
        contactAddrs: '',
      },
      formRules: {
        company: [
          { required: true, message: '请输入企业名称', trigger: 'blur' },
        ],
        taxNumber: [
          { required: true, message: '请输入税号', trigger: 'blur' },
        ],
        phoneNumber: [
          { validator: checkPhoneNumber, trigger: 'blur' },
        ],
      },
    };
  },
  methods: {
    close () {
      this.$emit('refresh', this.form.id);
    },
    save () {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          if (this.form.id) {
            await UserDrawer.updateInvoice(this.form);
            this.$message({
              type: 'success',
              message: '更新成功!',
            });
            this.$emit('refresh');
          } else {
            await UserDrawer.addInvoice(this.form);
            this.$message({
              type: 'success',
              message: '新增成功!',
            });
            this.$emit('refresh');
          }
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.invoice-form-box {
  display: flex;
  justify-content: space-between;
  padding: 15px;
  border-radius: 4px;
  border: 1px solid #DFE2E8;
  margin-bottom: 20px;
  .button-group {
    padding-left: 20px;
    flex-shrink: 0;
  }
}
</style>
