<template>
  <div class="user-basic">
    <div class="title">
      <div>个人信息</div>
      <div>
        <el-button v-if="!edit" @click="() => { edit = true }" size="small" type="primary">编辑</el-button>
        <el-button v-if="edit" @click="() => { edit = false }"  size="small">取消</el-button>
        <el-button v-if="edit" @click="save" size="small" type="primary">保存</el-button>
      </div>
    </div>
    <el-form
      :model="form"
      :rules="formRules"
      ref="form">
      <el-row>
        <el-col :span="12">
          <el-form-item v-if="edit" label="头像：" prop="headUrl" :label-width="labelWidth">
            <el-upload
              action="https://upload.qiniup.com"
              :accept="accept"
              :multiple="true"
              :show-file-list="false"
              :data="qiniuData"
              :before-upload="handleBeforeUpload"
              :on-success="uploadSuccess"
              :file-list="fileLists">
              <template slot="trigger">
                <img v-if="form.headUrl" :src="form.headUrl" class="head" />
                <el-avatar v-else :size="50" icon="el-icon-user-solid"></el-avatar>
              </template>
            </el-upload>
          </el-form-item>
          <div v-else class="item">
            <span class="label">头像</span>
            <img v-if="user.headUrl" :src="user.headUrl" class="head" />
            <el-avatar v-else :size="50" icon="el-icon-user-solid"></el-avatar>
          </div>
        </el-col>
        <el-col :span="12">
          <el-form-item v-if="edit" label="真实姓名：" prop="name" :label-width="labelWidth">
            <el-input v-model.trim="form.name" placeholder="请输入"></el-input>
          </el-form-item>
          <div v-else class="item">
            <span class="label">真实姓名</span>
            <span>{{ user.name || '--' }}</span>
          </div>
        </el-col>
        <el-col :span="12">
          <el-form-item v-if="edit" label="手机号：" :label-width="labelWidth">
            <el-input v-model.trim="userInfo.phoneNumber" :disabled="true"></el-input>
          </el-form-item>
          <div v-else class="item">
            <span class="label">手机号</span>
            <span>{{ userInfo.phoneNumber || '--' }}</span>
          </div>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item v-if="edit" label="职业/职位：" prop="profession" :label-width="labelWidth">
            <el-select v-model="form.profession" placeholder="请选择">
              <el-option
                v-for="item in professionList"
                :key="item.type"
                :label="item.label"
                :value="item.type">
              </el-option>
            </el-select>
          </el-form-item>
          <div v-else class="item">
            <span class="label">职业/职位</span>
            <span>{{ formatProfession(user.profession) }}</span>
          </div>
        </el-col>
        <el-col :span="12">
          <el-form-item v-if="edit" label="昵称：" prop="nickName" :label-width="labelWidth">
            <el-input v-model.trim="form.nickName" placeholder="请输入"></el-input>
          </el-form-item>
          <div v-else class="item">
            <span class="label">昵称</span>
            <span>{{ user.nickName || '--' }}</span>
          </div>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item v-if="edit" label="性别：" prop="gender" :label-width="labelWidth">
            <el-radio-group v-model="form.gender">
              <el-radio v-for="(item, index) in genderColumns" :key="'gender-'+index" :label="item.value">{{ item.label }}</el-radio>
            </el-radio-group>
          </el-form-item>
          <div v-else class="item">
            <span class="label">性别</span>
            <span>{{ formatGender(user.gender) }}</span>
          </div>
        </el-col>
        <el-col :span="12">
          <el-form-item v-if="edit" label="生日：" prop="birthday" :label-width="labelWidth">
            <el-date-picker
              v-model="form.birthday"
              type="date"
              placeholder="请选择日期"
              style="width: 100%;">
            </el-date-picker>
          </el-form-item>
          <div v-else class="item">
            <span class="label">生日</span>
            <span>{{ user.birthday ? formatDate(user.birthday) : '--' }}</span>
          </div>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item v-if="edit" label="邮箱：" prop="email" :label-width="labelWidth">
            <el-input v-model.trim="form.email" placeholder="请输入"></el-input>
          </el-form-item>
          <div v-else class="item">
            <span class="label">邮箱</span>
            <span>{{ user.email || '--' }}</span>
          </div>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item v-if="edit" label="所在地区：" prop="contactAreaId" :label-width="labelWidth">
            <area-cascader
              :provinceId.sync="form.contactProvinceId"
              :provinceName.sync="form.contactProvinceName"
              :cityId.sync="form.contactCityId"
              :cityName.sync="form.contactCityName"
              :areaId.sync="form.contactAreaId"
              :areaName.sync="form.contactAreaName"
            />
          </el-form-item>
          <div v-else class="item">
            <span class="label">所在地区</span>
            <span>{{ user.contactAreaId ? user.contactProvinceName+user.contactCityName+user.contactAreaName : '' || '--' }}</span>
          </div>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item v-if="edit" label="详细地址：" prop="contactAddrs" :label-width="labelWidth">
            <el-input v-model.trim="form.contactAddrs" placeholder="请输入"></el-input>
          </el-form-item>
          <div v-else class="item">
            <span class="label">详细地址</span>
            <span>{{ user.contactAddrs || '--' }}</span>
          </div>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item v-if="edit" label="个性签名：" prop="personalSignature" :label-width="labelWidth">
            <el-input v-model.trim="form.personalSignature" type="textarea" :rows="3" placeholder="请输入"></el-input>
          </el-form-item>
          <div v-else class="item">
            <span class="label">个性签名</span>
            <span>{{ user.personalSignature || '--' }}</span>
          </div>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import * as Moment from 'dayjs';
import { ProfessionEnum } from '@/enums/ProfessionEnum';
import AreaCascader from '@/components/common/areaCascader';
import Common from '@/api/common';
import UserDrawer from '@/api/userDrawer';
export default {
  name: 'USER_BASIC',
  components: {
    AreaCascader,
  },
  data () {
    const checkEmail = (rule, value, cb) => {
      const regEmail = /^(?=.*@)(?=.*\.)[^@.].*[^@.]$/;
      if (value && !regEmail.test(value)) {
        cb(new Error('请输入合法的邮箱'));
      }
      return cb();
    };
    return {
      userInfo: {},
      edit: false,
      labelWidth: '120px',
      professionList: ProfessionEnum,
      genderColumns: [
        { label: '男', value: '1' },
        { label: '女', value: '2' },
        { label: '保密', value: '3' },
      ],
      user: {},
      form: {
        id: '',
        headUrl: '',
        name: '',
        profession: '',
        nickName: '',
        gender: '3',
        birthday: '',
        email: '',
        personalSignature: '',
        contactProvinceId: '',
        contactProvinceName: '',
        contactCityId: '',
        contactCityName: '',
        contactAreaId: '',
        contactAreaName: '',
        contactAddrs: '',
      },
      formRules: {
        name: [
          { required: true, message: '请输入真实姓名', trigger: 'blur' },
        ],
        profession: [
          { required: true, message: '请选择职位/职业', trigger: 'blur' },
        ],
        email: [
          { validator: checkEmail, trigger: 'blur' },
        ],
      },
      // 头像上传
      accept: 'image/png,image/jpeg,image/jpg,image/bmp',
      maxSize: 3,
      downloadDomain: '',
      qiniuData: {
        key: '', // 图片名字处理
        token: '', // 七牛云token
        data: {},
      },
      fileLists: [],
    };
  },
  created () {
    let fileTypeMap = new Map();
    fileTypeMap.set('image/png', 'PNG');
    fileTypeMap.set('image/jpeg', 'JPEG');
    fileTypeMap.set('image/jpg', 'JPG');
    fileTypeMap.set('image/bmp', 'BMP');
    this.fileTypeMap = fileTypeMap;
    if (!this.disabled) {
      Common.getQinNiuToken().then(res => {
        this.qiniuData.token = res.body.token;
        this.downloadDomain = res.body.domain;
      });
    }
  },
  async mounted () {
    this.queryInfo();
  },
  methods: {
    ...mapGetters([
      'currentUserInfo',
    ]),
    formatDate (text, format = 'YYYY-MM-DD') {
      return text ? Moment(text * 1).format(format) : '/';
    },
    formatGender (val) {
      let label = '--';
      this.genderColumns.map(item => {
        if (item.value === val) {
          label = item.label;
        }
      });
      return label;
    },
    formatProfession (val) {
      let label = '--';
      this.professionList.map(item => {
        if (item.type === val) {
          label = item.label;
        }
      });
      return label;
    },
    handleBeforeUpload (file) {
      let isMatchFileType = this.accept.indexOf(file.type) >= 0;
      if (!isMatchFileType) {
        let desc = [];
        this.accept.split(',').forEach(s => {
          let d = this.fileTypeMap.get(s);
          d && desc.push(d);
        });
        this.$message.warning(`图片仅仅支持${desc.join(',')}格式`);
        return;
      }

      if (file.size / 1024 / 1024 > this.maxSize) {
        this.$message.warning(`图片大小不能超过${this.maxSize}MB`);
        return;
      }
      this.qiniuData.data = file;
      this.qiniuData.key = `${this.currentUserInfo.id}/${new Date().getTime()}/${file.name}`;
    },
    uploadSuccess (response) {
      const url = `${this.downloadDomain}/${response.key}`;
      this.form.headUrl = url;
    },
    save () {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          await UserDrawer.updateEmployee(this.form);
          this.$message({
            type: 'success',
            message: '修改成功!',
          });
          this.edit = false;
          this.queryInfo();
        }
      });
    },
    async queryInfo () {
      this.userInfo = this.$local.get('userInfo') ? JSON.parse(this.$local.get('userInfo')) : {};
      const { body } = await UserDrawer.employeeDetail({ id: this.userInfo.id, findDepartmentFlag: true, findRoleFlag: true });
      this.user = {
        id: body.id,
        headUrl: body.headUrl,
        name: body.name,
        profession: body.profession,
        nickName: body.nickName,
        gender: body.gender,
        birthday: body.birthday,
        email: body.email,
        personalSignature: body.personalSignature,
        contactProvinceId: body.contactProvinceId,
        contactProvinceName: body.contactProvinceName,
        contactCityId: body.contactCityId,
        contactCityName: body.contactCityName,
        contactAreaId: body.contactAreaId,
        contactAreaName: body.contactAreaName,
        contactAddrs: body.contactAddrs,
      };
      this.form = JSON.parse(JSON.stringify(this.user));
    },
  },
};
</script>
<style lang="scss" scoped>
.user-basic {
  padding: 15px;
  border-radius: 6px;
  background-color: #FFFFFF;
  margin-bottom: 10px;
  .title {
    padding-bottom: 15px;
    margin-bottom: 15px;
    border-bottom: 1px solid #DFE2E8;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .item {
    display: inline-flex;
    padding: 6px 10px;
    width: 50%;
    .label {
      flex-shrink: 0;
      width: 100px;
      text-align: right;
      padding-right: 15px;
      color: #929AA6;
    }
  }
  .head {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
  }
}
</style>
