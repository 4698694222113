<template>
  <div class="invoice">
    <div class="title">
      <div>开票信息</div>
      <el-button @click="() => {add = true}" size="small" type="primary">新增</el-button>
    </div>
    <template v-if="list.length">
      <div v-for="(item, index) in list" :key="index">
        <div v-if="!item.edit" class="invoice-row">
          <div class="info-group">
            <div class="item">
              <span class="label">企业名称</span>
              <span>{{ item.company }}</span>
            </div>
            <div class="item">
              <span class="label">税号</span>
              <span>{{ item.taxNumber }}</span>
            </div>
            <div class="item">
              <span class="label">开票电话</span>
              <span>{{ item.phoneNumber }}</span>
            </div>
            <div class="item">
              <span class="label">开户行</span>
              <span>{{ item.bank }}</span>
            </div>
            <div class="item">
              <span class="label">银行账号</span>
              <span>{{ item.bankAccount }}</span>
            </div>
            <div class="item">
              <span class="label">所在地区</span>
              <span>{{ item.contactCityId ? item.contactProvinceName+item.contactCityName+item.contactAreaName : '' }}</span>
            </div>
            <div class="item" style="width: 100%;">
              <span class="label">详细地址</span>
              <span>{{ item.contactAddrs }}</span>
            </div>
          </div>
          <div class="button-group">
            <el-button @click="edit(index)" icon="el-icon-edit" type="primary" plain>编辑</el-button>
            <el-button @click="disuse(item.id)" icon="el-icon-circle-close" type="info" plain>删除</el-button>
          </div>
        </div>
        <EditInvoice v-if="item.edit" :formData="item" @refresh="refresh" />
      </div>
    </template>
    <Empty v-if="!add && !list.length" />
    <EditInvoice v-if="add" @refresh="refresh" />
  </div>
</template>
<script>
import EditInvoice from './editInvoice.vue';
import UserDrawer from '@/api/userDrawer';
import Empty from '@/components/empty.vue';
export default {
  name: 'INVOICE',
  components: {
    EditInvoice,
    Empty,
  },
  data () {
    return {
      add: false,
      list: [],
      labelWidth: '120px',
    };
  },
  mounted () {
    this.queryList();
  },
  methods: {
    edit (index) {
      this.$set(this.list[index], 'edit', true);
    },
    refresh (id) {
      if (id) {
        this.list.map(item => {
          if (id === item.id) {
            item.edit = false;
          }
        });
      } else {
        this.add = false;
        this.queryList();
      }
    },
    disuse (id) {
      this.$confirm('此操作将永久删除该记录, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(async () => {
        await UserDrawer.deleteInvoice({ id });
        this.$message({
          type: 'success',
          message: '删除成功!',
        });
        this.queryList();
      }).catch(() => {});
    },
    async queryList () {
      let Api = '';
      const userInfo = this.$local.get('userInfo') ? JSON.parse(this.$local.get('userInfo')) : {};
      if (userInfo.isPersonal) {
        Api = UserDrawer.invoiceList;
      } else {
        Api = UserDrawer.invoiceOwn;
      }
      const { body } = await Api();
      this.list = body || [];
    },
  },
};
</script>
<style lang="scss" scoped>
.invoice {
  padding: 15px;
  border-radius: 6px;
  background-color: #FFFFFF;
  margin-bottom: 10px;
  .title {
    padding-bottom: 15px;
    margin-bottom: 15px;
    border-bottom: 1px solid #DFE2E8;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .invoice-row {
    display: flex;
    justify-content: space-between;
    padding: 15px;
    border-radius: 4px;
    border: 1px solid #DFE2E8;
    margin-bottom: 20px;
    .info-group {
      display: inline-flex;
      flex-wrap: wrap;
      .item {
        display: inline-flex;
        padding: 6px 10px;
        width: 50%;
        .label {
          flex-shrink: 0;
          width: 100px;
          text-align: right;
          padding-right: 15px;
          color: #929AA6;
        }
      }
    }
    .button-group {
      padding-left: 50px;
      flex-shrink: 0;
    }
    &:hover {
      background-color: #f9fafb;
    }
  }
}
</style>
